<template>
    <div class="page-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="
              page-title-box
              d-sm-flex
              align-items-center
              justify-content-between
            ">
                        <h4 class="mb-sm-0">Detail Pembelian Saham</h4>

                        <div class="page-title-right">
                            <ol class="breadcrumb m-0">
                                <li class="breadcrumb-item active">Daftar Pembelian Saham</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!share_purchase">
                <detail-share-purchase-shimmer />
            </div>

            <div v-else>
                <div class="card" id="demo">
                    <div class="card-header border-bottom-dashed p-4">
                        <div class="d-sm-flex">
                            <div class="flex-grow-1">
                                <img src="/assets/images/logo-dark.png" class="card-logo card-logo-dark" alt="logo dark"
                                    height="17">
                                <img src="/assets/images/logo-light.png" class="card-logo card-logo-light"
                                    alt="logo light" height="17">
                                <div class="mt-sm-5 mt-4">
                                    <h6 class="text-muted text-uppercase fw-semibold">Alamat</h6>
                                    <p class="text-muted mb-1" id="address-details">Pasar Dhuko Timur, Pamekasan</p>
                                    <p class="text-muted mb-0" id="zip-code"><span>Kode Pos:</span> 69383</p>
                                </div>
                            </div>
                            <div class="flex-shrink-0 mt-sm-0 mt-3">
                                <h6><span class="text-muted fw-normal">Nomor Registrasi : </span>
                                    <span id="legal-register-no">987654</span>
                                </h6>
                                <h6><span class="text-muted fw-normal">Email : </span>
                                    <span id="email">inalifeindonesia@gmail.com</span>
                                </h6>
                                <h6><span class="text-muted fw-normal">Website:</span> <a href="#" class="link-primary"
                                        target="_blank" id="website">inalife.com</a></h6>
                                <h6 class="mb-0"><span class="text-muted fw-normal">Nomor Telepon: </span><span
                                        id="contact-no"> +(62) 85233889578</span></h6>
                            </div>
                        </div>
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-4">
                        <div class="row g-3">
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Nomor Transaksi</p>
                                <h5 class="fs-14 mb-0">#<span id="invoice-no">{{ share_purchase['transaction_number']
                                }}</span></h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Tangggal</p>
                                <h5 class="fs-14 mb-0"><span id="invoice-date">{{ toDate(share_purchase['created_at'])
                                }}</span></h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Status</p>
                                <span v-if="share_purchase['status'] == 'Y'"
                                    class="badge rounded-pill bg-success">terverifikasi</span>
                                <span v-if="share_purchase['status'] == 'N'" class="badge rounded-pill bg-danger">tidak
                                    terverifikasi</span>
                                <span v-if="share_purchase['status'] == 'P'"
                                    class="badge rounded-pill bg-warning">menunggu</span>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Total</p>
                                <h5 class="fs-14 mb-0"><span id="total-amount">{{ toIdr(share_purchase['total_price'])
                                }}</span></h5>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4 border-top border-top-dashed">
                        <div class="table-responsive">
                            <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                                <thead>
                                    <tr class="table-active">
                                        <th scope="col" style="width: 50px;">#</th>
                                        <th scope="col">Periode Saham</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Harga Unit</th>
                                        <th scope="col" class="text-end">Total</th>
                                    </tr>
                                </thead>
                                <tbody id="products-list">
                                    <tr>
                                        <th scope="row">01</th>
                                        <td class="text-start">
                                            <span class="fw-medium">{{ share_purchase['share_period']['name'] }}</span>
                                        </td>
                                        <td>{{ share_purchase['number_of_slot'] }}</td>
                                        <td class="text-end">{{ toIdr(share_purchase['share_period']['price']) }}</td>
                                        <td class="text-end">{{ toIdr(share_purchase['total_price']) }}</td>
                                    </tr>
                                    <tr class="border-top border-top-dashed mt-2">
                                        <td colspan="2">
                                            <img :src="
                                                host +
                                                '/storage/uploads/share_purchases/' +
                                                share_purchase['evidance_of_transfer']
                                            " alt="" class="rounded" style="width: 200px;" />
                                        </td>
                                        <td colspan="3" class="fw-medium p-0">
                                            <table
                                                class="table table-borderless text-start table-nowrap align-middle mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td class="text-end">{{ toIdr(share_purchase['total_price']) }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Diskon <small class="text-muted"></small></td>
                                                        <td class="text-end">-</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Akhir</td>
                                                        <td class="text-end">{{ toIdr(share_purchase['total_price']) }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!--end table-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--end table-->
                        </div>
                        <div class="mt-3">
                            <h6 class="text-muted text-uppercase fw-semibold mb-3">Detail Pembayaran:</h6>
                            <p class="text-muted mb-1">Metode Pembayaran: <span class="fw-medium"
                                    id="payment-method">Transfer Bank</span></p>
                            <p class="text-muted mb-1">Nomor Rekeing: <span class="fw-medium"
                                    id="card-holder-name">646401032252538</span></p>
                            <p class="text-muted mb-1">Atas Nama: <span class="fw-medium" id="card-number">INALIFE
                                    INDONESIA</span></p>
                        </div>
                        <div class="mt-4">
                            <div class="alert alert-info">
                                <p class="mb-0">Terima kasih banyak karena Anda terus membeli produk kami. Perusahaan
                                    kami berjanji untuk menyediakan produk berkualitas tinggi untuk Anda serta layanan
                                    pelanggan yang luar biasa untuk setiap transaksi.
                                </p>
                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-end d-print-none mt-4">
                            <router-link :to="{ name: 'user.share-purchase-history' }" type="button"
                                class="btn btn-warning waves-effect waves-light me-2"><i
                                    class="bx bx-chevrons-left font-size-16 align-middle me-2"></i>
                                Kembali
                            </router-link>
                            <a href="javascript:window.print()" class="btn btn-success"><i
                                    class="ri-printer-line align-bottom me-1"></i> Print</a>
                        </div>
                    </div>
                    <!--end card-body-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { endpoint, host } from "../../host";
import moment from "moment";
import httpService from "../../services/http-service";
import errorService from "../../services/error-service";
import numberService from "../../services/number-service";
import Swal from "sweetalert2";
import globalService from "../../services/global-service";
export default {
    components: {
    },

    created() {
        this.id = this.$route.params.id;
        this.getTransaction();
    },

    data() {
        return {
            load: false,
            id: null,
            share_purchase: null,
            host: host,
            currency: numberService,
            moment: moment,
        };
    },
    methods: {
        async getTransaction() {
            try {
                let res = await httpService.get(
                    endpoint["share.purchase"] + `/show/${this.id}`,
                    {
                        headers: httpService.authHeader(),
                    }
                );
                this.share_purchase = res.data.data;
            } catch (error) {
                errorService.displayError(error.response);
            }
        },

        async confirmValidation(value) {
            Swal.fire({
                title: "Apakah anda yakin ingin mengubah status menjadi " + value + "?",
                text: "Setalah menggati status anda tidak dapat mengubanya lagi",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#405189",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ya, ganti",
                cancelButtonText: "Tidak, batalkan",
                buttonsStyling: true,
            }).then((isConfirm) => {
                if (isConfirm.value === true) {
                    this.validateSharePurchase(value);
                }
            });
        },

        async validateSharePurchase(value) {
            try {
                this.load = true;
                let data = {
                    status: value == "valid" ? "Y" : "N",
                    _method: "PUT",
                };
                let res = await httpService.post(
                    endpoint["share.purchase"] + `/status/${this.id}`,
                    data,
                    {
                        headers: httpService.authHeader(),
                    }
                );
                this.load = false;
                if (res.status == 200) {
                    Swal.fire({
                        title: "Berhasil",
                        text: "data berhasil disimpan",
                        icon: "success",
                    });
                    this.getTransaction();
                }
            } catch (error) {
                this.load = false;
                errorService.displayError(error);
            }
        },

        toDate(a) {
            return globalService.dateFormat2(a);
        },

        toIdr(a) {
            return globalService.toIdr(a);
        }
    },
};
</script>

<style>
</style>